import { ActionData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../components/View';
import Form from '../../../../../containers/Espace/Form';
import action from '../../../../../params/action.json';
import TemplateEspace from '../../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../../utils/requireEspace';
import { UserProps } from '../../../../../utils/requireUser';

const PageAutomatisationActionCreate: FC<
  PageProps & EspaceProps & UserProps
> = ({ espace, params: { espaceId }, user }) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        itemPathnamePrefix={`/espaces/${espaceId}/automatisation/actions/`}
        model={
          new ActionData({
            espaceId,
            params: action,
          })
        }
        name="item"
        type="create"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageAutomatisationActionCreate);
